import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import axios from 'axios';
import * as locales from 'date-fns/locale';
import { Configuration } from './Interfaces';
import common from '../../../locales/en/common.json';
import messages from '../../../locales/en/messages.json';
import alerts from '../../../locales/en/alerts.json';
import accountManagement from '../../../locales/en/accountManagement.json';
import RuntimeConfigurationProvider from './RuntimeConfigurationProvider';
import registration from '../../../locales/en/pages/registration.json';
import validations from '../../../locales/en/validations.json';
import portfolio from '../../../locales/en/pages/portfolio.json';
import esignConsent from '../../../locales/en/pages/esignConsent.json';
import onlineAccessAgreement from '../../../locales/en/pages/onlineAccessAgreement.json';
import onlineRegistration from '../../../locales/en/pages/onlineRegistration.json';
import investmentPlans from '../../../locales/en/pages/dspp/investmentPlans.json';
import companyDetails from '../../../locales/en/pages/dspp/companyDetails.json';
import dsppVerify from '../../../locales/en/pages/dspp/verify.json';
import taxCertCheck from '../../../locales/en/pages/dspp/taxCertCheck.json';
import dividendPaymentMethod from '../../../locales/en/pages/dspp/dividendPaymentMethod.json';
import yourInvestment from '../../../locales/en/pages/dspp/yourInvestment.json';
import taxCertificationForm from '../../../locales/en/components/taxCertificationForm.json';
import taxCertificationDialog from '../../../locales/en/components/taxCertificationDialog.json';
import payment from '../../../locales/en/pages/dspp/payment.json';
import registrationDetails from '../../../locales/en/components/registrationDetails.json';
import bankInfo from '../../../locales/en/bankInfo.json';
import dividendReinvestment from '../../../locales/en/pages/dspp/dividendReinvestment.json';
import reviewCommon from '../../../locales/en/components/review.json';
import dsppReview from '../../../locales/en/pages/dspp/review.json';
import summary from '../../../locales/en/pages/dspp/summary.json';
import buySummary from '../../../locales/en/pages/buy/summary.json';
import stopLossAndLimitOrders from '../../../locales/en/pages/stopLossAndLimitOrders.json';
import dividends from '../../../locales/en/pages/dividends.json';
import accountDocuments from '../../../locales/en/pages/accountDocuments.json';
import balanceSummary from '../../../locales/en/pages/accountBalance/balanceSummary.json';
import verifySSN from '../../../locales/en/pages/verifySSN.json';
import buyWizard from '../../../locales/en/pages/buy/buyWizard.json';
import buyCompanyDetails from '../../../locales/en/pages/buy/companyDetails.json';
import buyInvestmentPlans from '../../../locales/en/pages/buy/investmentPlans.json';
import buyReview from '../../../locales/en/pages/buy/review.json';
import buySelectAccount from '../../../locales/en/pages/buy/selectAccount.json';
import buyTexVerification from '../../../locales/en/pages/buy/taxVerification.json';
import companyComponents from '../../../locales/en/components/companies.json';
import CancelDialog from '../../../locales/en/components/cancelDialog.json';
import RedirectDialog from '../../../locales/en/components/redirectDialog.json';
import taxCertificationDspp from '../../../locales/en/pages/dspp/taxCertificationDspp.json';
import taxCertificationBuy from '../../../locales/en/pages/buy/taxCertificationBuy.json';
import taxCertificationTrustForm from '../../../locales/en/pages/dspp/taxCertificationTrustFormData.json';
import registrationSummary from '../../../locales/en/pages/buy/registrationSummary.json';
import investmentDetailsForms from '../../../locales/en/components/investmentDetailsForms.json';
import investmentDetails from '../../../locales/en/pages/buy/investmentDetails.json';
import investmentEdit from '../../../locales/en/pages/buy/investmentEdit.json';
import paymentDetails from '../../../locales/en/pages/buy/paymentDetails.json';
import buyRegistrationDetails from '../../../locales/en/pages/buy/registrationDetails.json';
import eDeliveryPreferences from '../../../locales/en/pages/buy/eDeliveryPreferences.json';
import accountBalanceReports from '../../../locales/en/pages/accountBalance/accountBalanceReports.json';

declare module 'react-i18next' {
  interface CustomResources {
    common: typeof common;
    messages: typeof messages;
    alerts: typeof alerts;
    accountManagement: typeof accountManagement;
    validations: typeof validations;
    bankInfo: typeof bankInfo;
    'pages/portfolio': typeof portfolio;
    'pages/registration': typeof registration;
    'components/registrationDetails': typeof registrationDetails;
    'pages/dspp/investmentPlans': typeof investmentPlans;
    'pages/dspp/dividendReinvestment': typeof dividendReinvestment;
    'pages/dspp/companyDetails': typeof companyDetails;
    'pages/dspp/verify': typeof dsppVerify;
    'pages/dspp/taxCertCheck': typeof taxCertCheck;
    'pages/dspp/dividendPaymentMethod': typeof dividendPaymentMethod;
    'pages/dspp/yourInvestment': typeof yourInvestment;
    'components/taxCertificationForm': typeof taxCertificationForm;
    'components/taxCertificationDialog': typeof taxCertificationDialog;
    'pages/dspp/payment': typeof payment;
    'pages/esignConsent': typeof esignConsent;
    'pages/onlineAccessAgreement': typeof onlineAccessAgreement;
    'pages/onlineRegistration': typeof onlineRegistration;
    'components/review': typeof reviewCommon;
    'pages/dspp/review': typeof dsppReview;
    'pages/dspp/summary': typeof summary;
    'pages/buy/summary': typeof buySummary;
    'pages/stopLossAndLimitOrders': typeof stopLossAndLimitOrders;
    'pages/dividends': typeof dividends;
    'pages/accountDocuments': typeof accountDocuments;
    'pages/accountBalance/accountBalanceReports': typeof accountBalanceReports;
    'pages/accountBalance/balanceSummary': typeof balanceSummary;
    'pages/verifySSN': typeof verifySSN;
    'pages/buy/companyDetails': typeof buyCompanyDetails;
    'pages/buy/investmentPlans': typeof buyInvestmentPlans;
    'pages/buy/review': typeof buyReview;
    'pages/buy/buyWizard': typeof buyWizard;
    'pages/buy/selectAccount': typeof buySelectAccount;
    'pages/buy/taxVerification': typeof buyTexVerification;
    'pages/buy/taxCertificationBuy': typeof taxCertificationBuy;
    'pages/buy/registrationSummary': typeof registrationSummary;
    'pages/buy/investmentDetails': typeof investmentDetails;
    'pages/buy/investmentEdit': typeof investmentEdit;
    'pages/buy/paymentDetails': typeof paymentDetails;
    'pages/buy/registrationDetails': typeof buyRegistrationDetails;
    'pages/buy/eDeliveryPreferences': typeof eDeliveryPreferences;
    'pages/dspp/taxCertificationDspp': typeof taxCertificationDspp;
    'pages/dspp/taxCertificationTrustFormData': typeof taxCertificationTrustForm;
    'components/companies': typeof companyComponents;
    'components/cancelDialog': typeof CancelDialog;
    'components/redirectDialog': typeof RedirectDialog;
    'components/investmentDetailsForms': typeof investmentDetailsForms;
  }

  type CountryStatesKeys = Exclude<string, keyof CustomResources>;
  type CountryStatesResources = Record<
    CountryStatesKeys,
    { states: { name: string; code: string }[] }
  >;

  interface CustomTypeOptions {
    defaultNS: 'common';
    resources: CustomResources & CountryStatesResources;
  }
}

const UpdateLocalStorageWithLatestMetadata = (configuration: Configuration) => {
  axios
    .get(`${configuration.LocalesBaseUrl}/locales-metadata.json`)
    .then((res) => {
      if (res.data) {
        localStorage.setItem('locales-metadata', JSON.stringify(res.data));
      }
    });
};

const DetermineLocalesVersionBasedOnMetadaAndConfig = (
  configuration: Configuration
) => {
  const localesJson = localStorage.getItem('locales-metadata');
  if (localesJson) {
    const metadataObject = JSON.parse(localesJson);
    const [metadataDate, metadataIncrement] = metadataObject.version.split('.');
    const [confDate, confIncrement] = (
      configuration.LocalesVersion || '0.0'
    ).split('.');
    if (metadataDate === confDate) {
      return Number(metadataIncrement) > Number(confIncrement)
        ? metadataObject.version
        : configuration.LocalesVersion;
    }
    if (metadataDate > confDate) {
      return metadataObject.version;
    }
    return configuration.LocalesVersion;
  }
  return configuration.LocalesVersion;
};

const getCurrentLocalesVersionAndUpdateLocalStorage = (
  configuration: Configuration
) => {
  UpdateLocalStorageWithLatestMetadata(configuration);
  return DetermineLocalesVersionBasedOnMetadaAndConfig(configuration);
};

const getLocalizationFilesPath = (configuration: Configuration) => {
  const base = configuration.LocalesBaseUrl;
  const version = configuration.LocalesVersion
    ? `/${getCurrentLocalesVersionAndUpdateLocalStorage(configuration)}`
    : '';

  return `${base}${version}/{{lng}}/{{ns}}.json`;
};

export const getI18nOptions = () => ({
  lng: 'en',
  fallbackLng: 'en',
  interpolation: { escapeValue: false },
  defaultNS: 'common',
  returnObjects: true,
  ns: ['common'],
});

const initLocalization = (configuration: Configuration) => {
  i18n
    .use(HttpApi)
    .use(initReactI18next)
    .init({
      ...getI18nOptions(),
      debug: configuration.UseLocalizationDebug,
      backend: {
        loadPath: getLocalizationFilesPath(configuration),
      },
    });
};

export const decorateWithLocalMediaBaseUrl = (url: string) => {
  const configuration = new RuntimeConfigurationProvider().getConfiguration();
  return url.replace('$(LOCAL_MEDIA_URL)', configuration.MediaBaseUrl || '');
};

export const decorateWithDesignSystemMediaBaseUrl = (url: string) => {
  const configuration = new RuntimeConfigurationProvider().getConfiguration();
  return url.replace(
    '$(DESIGN_SYSTEM_MEDIA_URL)',
    configuration.DesignSystemMediaBaseUrl || ''
  );
};

export const userLocale: string =
  navigator.languages && navigator.languages.length
    ? navigator.languages[0]
    : // @ts-ignore // doesn't recognise IE navigator fields
      navigator.userLanguage ||
      navigator.language ||
      // @ts-ignore // doesn't recognise IE navigator fields
      navigator.browserLanguage ||
      'en-US';

export const userLocaleObject =
  // @ts-ignore // dynamic locales loader
  locales[userLocale.replace('-', '')] ||
  // @ts-ignore // dynamic locales loader
  locales[userLocale.substring(0, 2)] ||
  locales.enUS;

export default initLocalization;
