import React from 'react';
import { CssBaseline, ScopedCssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import eqMainTheme, {
  EqMainThemeType,
} from '@eq/design-system/themes/eqMainTheme';
import merge from 'lodash/merge';
import configuration from '../../app/configuration';

declare module '@mui/material/styles' {
  interface Theme {
    external: {
      bp: {
        palette: {
          primary: {
            main: string;
          };
        };
      };
      comcast: {
        palette: {
          primary: {
            main: string;
          };
        };
      };
    };
  }

  interface ThemeOptions {
    external: {
      bp: {
        palette: {
          primary: {
            main: string;
          };
        };
      };
      comcast: {
        palette: {
          primary: {
            main: string;
          };
        };
      };
    };
  }
}

export const getSolTheme = () => {
  const theme = eqMainTheme as EqMainThemeType;
  const { fontSize, ...linkBase } = theme.typography.link5;
  const customs = {
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          p: {
            margin: 0,
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            backgroundColor: '#19316F',
            '& .MuiTooltip-arrow': {
              color: '#19316F',
            },
          },
        },
      },
      MuiScopedCssBaseline: {
        styleOverrides: {
          root: {
            ...(theme.components!.MuiCssBaseline!.styleOverrides as any),
            ...(theme.components!.MuiCssBaseline!.styleOverrides as any).body,
            color: 'black',
            '&:empty': {
              minHeight: 0,
            },
            '*::selection': {
              backgroundColor: '#D8E3FF',
            },
            a: {
              ...linkBase,
            },
            button: {
              '&:focus-visible': {
                outline: '5px auto',
                outlineColor: 'black',
              },
              '&:focus:not(:focus-visible)': {
                outline: 'none',
              },
            },
          },
        },
      },
      MuiModal: {
        styleOverrides: {
          root: {
            ...(theme.components!.MuiCssBaseline!.styleOverrides as any).body,
            a: {
              ...linkBase,
              '&.MuiMenuItem-root': {
                color: 'black',
                textDecoration: 'none',
              },
            },
            '&.MuiDrawer-root a': {
              color: 'black',
              textDecoration: 'none',
            },
            button: {
              '&:focus-visible': {
                outline: '5px auto',
                outlineColor: 'black',
              },
              '&:focus:not(:focus-visible)': {
                outline: 'none',
              },
            },
            '.PrivatePickersYear-root button': { fontSize: '1rem' },
          },
        },
      },
      MuiDatePicker: {
        defaultProps: {
          PaperProps: {
            sx: {
              '.PrivatePickersYear-root button': { fontSize: '1rem' },
            },
          },
        },
      },
    },

    external: {
      bp: {
        palette: {
          primary: {
            main: '#009B00',
          },
        },
      },
      comcast: {
        palette: {
          primary: {
            main: '#0088D2',
          },
        },
      },
    },
  };

  return merge(theme, customs) as EqMainThemeType & typeof customs;
};

export const solTheme = getSolTheme();
export type SolTheme = typeof solTheme;

const SolThemeProvider = ({
  children,
  baseMediaUrl = undefined,
}: {
  children: React.ReactNode;
  baseMediaUrl?: string;
}) => {
  if (baseMediaUrl) {
    solTheme.baseMediaUrl = baseMediaUrl;
  }

  return (
    <ThemeProvider theme={solTheme}>
      {configuration.IsStandalone && <CssBaseline />}
      <ScopedCssBaseline>{children}</ScopedCssBaseline>
    </ThemeProvider>
  );
};

export default SolThemeProvider;
