/* eslint-disable no-param-reassign */
import {
  RegistrationDataDto,
  ValidateUserAuthResponse,
  SosUserAuthResponseCode,
  ValidateCompanyNameAndAccountNumberRequest,
} from '@equs/sp-api-client';
import { ValidateCompanyNameAndAccountNumberResponse } from '@equs/sp-api-client/ApiClient';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import apiClient from '../../../app/apiClient';
import { ValidateErrorResponse } from '../../interfaces/Errors';
import createApiThunk from '../utils';
import emptyApi from '../emptyApi';

export type RegistrationSliceState = {
  isLoading: boolean;
  correlationId: string;
  redirectUrl?: string;
  isWaitingOnSsnVerification?: boolean;
  isRecoveryLoading: boolean;
  recoveryResponse?: AccountRecoveryResponse;
  isError: boolean;
  isValidationSuccess: boolean;
  validationStatus: SosUserAuthResponseCode;
  cache: RegistrationDataDto;
};

export type AccountRecoveryResponse = {
  authenticationId?: string | undefined;
  isEmailAvailable?: boolean;
  isError?: boolean;
  errorCode?: number;
  errorMessage?: string | undefined;
  status?: string | undefined;
  message?: string | undefined;
  code?: number;
};

export const initialState: RegistrationSliceState = {
  isLoading: false,
  correlationId: '',
  isRecoveryLoading: false,
  recoveryResponse: undefined,
  isError: false,
  isValidationSuccess: false,
  validationStatus: SosUserAuthResponseCode.GeneralError,
  cache: {
    payload: '',
  } as any as RegistrationDataDto,
};

export const fetchRegistration = createAsyncThunk(
  'registration/load',
  async (request: RegistrationDataDto) => {
    const response = await apiClient.registrationCache_Create(request);
    return response.correlationId;
  }
);

export const fetchRecovery = createApiThunk(
  'registration/recovery',
  (req: any) => apiClient.recovery_RecoverAuthenticationId(req)
);

export const fetchValidateAuthenticationId = createApiThunk(
  'registration/validate-authenticationId',
  (req: any) =>
    apiClient.registrationValidation_ValidateUserByAuthenticationId(req)
);

export const fetchValidateSSN = createApiThunk(
  'registration/validate-ssn',
  (req: any) =>
    apiClient.registrationValidation_ValidateUserBySocialSecurityNumber(req)
);

export const fetchValidateEmployeeNumber = createApiThunk(
  'registration/validate-employyee-number',
  (req: any) =>
    apiClient.registrationValidation_ValidateUserByEmployeeNumber(req)
);

export const resetRegistrationReducer = createAsyncThunk(
  'registration/reset',
  async (_, { rejectWithValue }) =>
    new Promise((resolve) => resolve(rejectWithValue(null)))
);

const registrationSlice = createSlice({
  name: 'registration',
  initialState,
  reducers: {
    resetRegistrationRedirectUrl: (state) => {
      state.redirectUrl = '';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRegistration.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchRegistration.fulfilled, (state, action) => {
      state.isLoading = false;
      state.correlationId = action.payload || '';
    });
    builder.addCase(fetchRegistration.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(fetchRecovery.pending, (state) => {
      state.isRecoveryLoading = true;
    });
    builder.addCase(fetchRecovery.fulfilled, (state, action) => {
      state.isRecoveryLoading = false;
      state.recoveryResponse = action.payload as AccountRecoveryResponse;
    });
    builder.addCase(fetchRecovery.rejected, (state, action) => {
      const error = action.payload as ValidateErrorResponse;
      state.isRecoveryLoading = false;
      state.recoveryResponse = {
        status: error?.status?.toString(),
        errorCode: error?.title,
        isError: true,
        errorMessage:
          error?.detail && error?.detail?.length > 0 && error?.detail[0],
        error: error?.detail,
      } as AccountRecoveryResponse;
    });
    builder.addCase(fetchValidateAuthenticationId.pending, (state) => {
      state.isLoading = true;
      state.isError = false;
    });
    builder.addCase(
      fetchValidateAuthenticationId.fulfilled,
      (state, action) => {
        const { validationCode, correlationId } =
          action.payload as ValidateUserAuthResponse;
        state.isLoading = false;
        state.isError = validationCode !== SosUserAuthResponseCode.Success;
        state.isValidationSuccess =
          validationCode === SosUserAuthResponseCode.Success;
        state.validationStatus =
          validationCode || SosUserAuthResponseCode.GeneralError;
        state.correlationId = correlationId || '';
      }
    );
    builder.addCase(fetchValidateAuthenticationId.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
      state.isValidationSuccess = false;
      state.validationStatus = SosUserAuthResponseCode.GeneralError;
    });
    builder.addCase(fetchValidateEmployeeNumber.pending, (state) => {
      state.isLoading = true;
      state.isError = false;
    });

    builder.addCase(fetchValidateEmployeeNumber.fulfilled, (state, action) => {
      const { validationCode, correlationId } =
        action.payload as ValidateUserAuthResponse;
      state.isLoading = false;
      state.isError = validationCode !== SosUserAuthResponseCode.Success;
      state.isValidationSuccess =
        validationCode === SosUserAuthResponseCode.Success;
      state.validationStatus =
        validationCode || SosUserAuthResponseCode.GeneralError;
      state.correlationId = correlationId || '';
    });
    builder.addCase(fetchValidateEmployeeNumber.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
      state.isValidationSuccess = false;
      state.validationStatus = SosUserAuthResponseCode.GeneralError;
    });
    builder.addCase(fetchValidateSSN.pending, (state) => {
      state.isLoading = true;
      state.isError = false;
      state.redirectUrl = '';
      state.correlationId = '';
      state.isWaitingOnSsnVerification = true;
    });
    builder.addCase(fetchValidateSSN.fulfilled, (state, action) => {
      const { validationCode, correlationId, redirectUrl } =
        action.payload as ValidateUserAuthResponse;
      state.isLoading = false;
      state.isError = validationCode !== SosUserAuthResponseCode.Success;
      state.isValidationSuccess =
        validationCode === SosUserAuthResponseCode.Success;
      state.validationStatus =
        validationCode || SosUserAuthResponseCode.GeneralError;
      state.correlationId = correlationId || '';
      state.redirectUrl = redirectUrl || '';
      state.isWaitingOnSsnVerification = false;
    });
    builder.addCase(fetchValidateSSN.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
      state.isValidationSuccess = false;
      state.validationStatus = SosUserAuthResponseCode.GeneralError;
      state.isWaitingOnSsnVerification = false;
    });
    builder.addCase(resetRegistrationReducer.pending, (state) => {
      state.isLoading = true;
      state.isError = false;
    });
    builder.addCase(resetRegistrationReducer.rejected, (state) => {
      state.isLoading = false;
      state.isError = false;
    });
  },
});

export default registrationSlice.reducer;

export const validateCompanyAndAccountNumber = async (
  request: ValidateCompanyNameAndAccountNumberRequest
) =>
  apiClient
    .registrationValidation_ValidateCompanyNameAndAccountNumber(request)
    .then((response: ValidateCompanyNameAndAccountNumberResponse) => ({
      data: response,
    }));

export const registrationApi = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    validateCompanyAndAccountNumber: build.mutation<
      ValidateCompanyNameAndAccountNumberResponse,
      ValidateCompanyNameAndAccountNumberRequest
    >({
      queryFn: validateCompanyAndAccountNumber,
    }),
  }),
  overrideExisting: false,
});

export const { resetRegistrationRedirectUrl } = registrationSlice.actions;
export const { useValidateCompanyAndAccountNumberMutation } = registrationApi;
