import { SxProps } from '@mui/system';
import { Spacings } from '@eq/design-system/themes';

const backToLogin: SxProps = {
  textAlign: 'right',
  '& a': {
    typography: 'link5Heavy',
    marginLeft: Spacings.small2,
  },
};

export default {
  backToLogin,
};
