import DsHeader, {
  HeaderLink,
  HeaderProps,
  ThemeVariant,
} from '@eq/design-system/components/Header';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useMediaQuery } from '@mui/material';
import { SxProps } from '@mui/system';
import { fonts } from '@eq/design-system/themes/eqMainTheme';
import { useFeatures } from 'flagged';
import configuration from '../../../app/configuration';
import { useAppDispatch } from '../../../app/store';
import { useAuth } from '../../Auth';
import { hasUnreadAlertsSelector } from '../../slices/alerts/alertsSelectors';
import { fetchAlertsByUserId } from '../../slices/alerts/alertsSlice';
import { hasUnreadMessagesSelector } from '../../slices/messages/messagesSelectors';
import { fetchMessagesByUserId } from '../../slices/messages/messagesSlice';
import {
  decorateWithDesignSystemMediaBaseUrl,
  decorateWithLocalMediaBaseUrl,
} from '../../../app/configuration/Localization';
import CompaniesComponentsFactory from '../Factories/CompaniesComponentsFactory';
import RoutingPaths, { registerPaths } from '../../../routing/RoutingConstants';
import styles from './Header.styles';
import { solTheme } from '../SolThemeProvider';
import { isNewSol, getUrl } from '../../utils/urlUtils';

export interface FeatureHeaderLink extends HeaderLink {
  disableOnFeature?: string | undefined;
  enableOnFeature?: string | undefined;
}

const Header = ({ sx = {} }: { sx?: SxProps }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const isMobile = useMediaQuery(solTheme.breakpoints.down('sm'));
  const [companyHeader, companyStyles] = CompaniesComponentsFactory(
    location.pathname
  );
  const hasMessages = useSelector(hasUnreadMessagesSelector);
  const hasAlerts = useSelector(hasUnreadAlertsSelector);
  const {
    isAuthenticated,
    signIn,
    signOut,
    profileSettings,
    isPerusal,
    allowESPPManagement,
  } = useAuth();
  const features = useFeatures();
  const headerData = t('header', { returnObjects: true }) as HeaderProps;
  const isRegisterPath = (registerPaths as string[]).includes(
    location.pathname
  );
  const isDsppContinue = location.pathname.includes(
    RoutingPaths.registerDsppContinue
  );
  const accountLinks = !configuration.IsStandalone
    ? headerData.accountLinks
    : [
        ...headerData.accountLinks.slice(0, -2),
        {
          ...headerData.accountLinks[headerData.accountLinks.length - 2],
          url: '',
          onClick: () => profileSettings(),
        },

        {
          ...headerData.accountLinks[headerData.accountLinks.length - 1],
          url: '',
          onClick: () => signOut(),
        },
      ];

  useEffect(() => {
    if (isAuthenticated && !isDsppContinue) {
      dispatch(fetchAlertsByUserId());
      dispatch(fetchMessagesByUserId());
    }
  }, [isAuthenticated]);

  const urlGenerator = (url: string | undefined, isExternal?: boolean) => ({
    url: getUrl(url),
    externalUrl: isExternal || !isNewSol,
  });

  const featureFilter = (link: FeatureHeaderLink) => {
    if (link.disableOnFeature) {
      return features[link.disableOnFeature] !== true;
    }
    if (link.enableOnFeature) {
      return features[link.enableOnFeature] === true;
    }
    return true;
  };

  const urlArrayGenerator = (array: Array<HeaderLink>) => {
    const data = array.filter((item: FeatureHeaderLink) => {
      if (!allowESPPManagement && item.label === 'Employee Plan') return false;

      return featureFilter(item);
    });

    return data.map((el) =>
      el.subLinks
        ? ({
            ...el,
            subLinks: el.subLinks?.filter(featureFilter)?.map(
              (sub) =>
                ({
                  ...sub,
                  ...urlGenerator(sub.url, sub.externalUrl),
                } as HeaderLink)
            ),
          } as HeaderLink)
        : {
            ...el,
            ...urlGenerator(el.url, el.externalUrl),
          }
    );
  };

  const logoData = {
    ...headerData.logo,
    image: decorateWithLocalMediaBaseUrl(headerData.logo.image),
  };
  const decoratedLinks = accountLinks.map((aL) => ({
    ...aL,
    iconUrl: decorateWithDesignSystemMediaBaseUrl(aL.iconUrl || ''),
    disabled:
      aL.url === '/umbraco/surface/ProfileSettingsSurface/Redirect'
        ? isPerusal
        : false,
  }));
  const decoratedMobileLinks = headerData.mobileAccountLinks.map((aL) => ({
    ...aL,
    iconUrl: decorateWithDesignSystemMediaBaseUrl(aL.iconUrl || ''),
    disabled:
      aL.url === '/umbraco/surface/ProfileSettingsSurface/Redirect'
        ? isPerusal
        : false,
  }));

  const isCompanyPage =
    location.pathname.startsWith('/bp') ||
    location.pathname.startsWith('/comcast');

  const buttonsSettings = !configuration.IsStandalone
    ? {
        ...headerData.buttonsSettings,
        registerLink: `/transactional/anonymous/newsol${
          isCompanyPage ? '/external' : ''
        }${headerData.buttonsSettings.registerLink}`,
      }
    : {
        ...headerData.buttonsSettings,
        loginLink: '',
        onLoginClick: (e: { preventDefault: () => void }) => {
          e.preventDefault();
          signIn();
        },
        loginExternal: false,
        registerLink: `${isCompanyPage ? '/external' : ''}${
          headerData.buttonsSettings.registerLink
        }`,
        registerExternal: false,
      };

  const goToLoginText = (
    <Box sx={styles.backToLogin}>
      {!isMobile && (
        <Typography variant="body5Medium">
          {t('headerAddons.backToLogin')}
        </Typography>
      )}
      <Link to={RoutingPaths.portfolio}>
        {t('headerAddons.backToLoginLink')}
      </Link>
    </Box>
  );

  return (
    <>
      {headerData && (
        <DsHeader
          themeVariant={ThemeVariant.light}
          messages={{
            hasMessages,
            ...urlGenerator(
              headerData.messages.url,
              headerData.messages.externalUrl
            ),
          }}
          notifications={{
            ...urlGenerator(
              headerData.notifications.url,
              headerData.notifications.externalUrl
            ),
            hasNotifications: hasAlerts,
          }}
          isLoggedIn={isAuthenticated && !isDsppContinue}
          menuElements={urlArrayGenerator(headerData.menuElements)}
          accountLinks={urlArrayGenerator(decoratedLinks)}
          logo={logoData}
          mobileAccountLinks={urlArrayGenerator(decoratedMobileLinks)}
          buttonsSettings={buttonsSettings}
          hideLoginRegister={isRegisterPath}
          additionalContent={
            isRegisterPath && !isAuthenticated ? goToLoginText : companyHeader
          }
          hideMobileMenu={isMobile && isRegisterPath && !isDsppContinue}
          sx={{
            '& .MuiToolbar-root .MuiDivider-root': {
              width: 0,
            },
            '& .MuiIconButton-root:hover': {
              backgroundColor: 'rgba(0,0,0,0.04)',
            },
            '& .MuiButtonBase-root:focus': {
              outline: 'unset',
            },
            '& #registerButton': {
              color: '#de0001',
              borderColor: '#de0001',
              '&:hover': {
                borderColor: '#de0001',
              },
            },
            pr: '0px !important',
            '& #loginButton': {
              color: 'white',
              backgroundColor: '#de0001',
              border: '1px solid #de0001',
              '&:hover': {
                backgroundColor: 'white',
                color: '#de0001',
              },
            },
            a: {
              color: 'black',
              fontFamily: fonts.Heavy,
              textDecoration: 'none',
              ':hover': {
                textDecorationColor: 'inherit',
              },
            },
            ...sx,
            ...companyStyles,
          }}
        />
      )}
    </>
  );
};

export default Header;
