enum RoutingPaths {
  root = '/',
  bp = '/bp',
  comcast = '/comcast',
  messages = '/messages',
  messageDetail = '/messages/:messageId',
  contactUs = '/messages/contact-us',
  stopLossAndLimitOrders = '/stop-loss-and-limit-orders',
  portfolio = '/portfolio',
  accountDocuments = '/account-documents',
  shareBalance = '/share-balance',
  accountBalanceReports = '/account-balance-reports',
  historicalAccountBalance = '/account-balance-reports/historical-share-balance',
  currentAccountBalance = '/account-balance-reports/current-share-balance',
  dividends = '/dividends',
  accountManagement = '/account-management',
  alerts = '/alerts',
  register = '/register',
  registerDspp = '/register/dspp',
  registerDsppContinue = '/register/dspp/continue',
  externalRegister = '/external/register',
  resetProfileVerify = '/reset-profile/verify',
  onlineRegistration = '/register/onlineRegistration',
  accountInformation = '/account-management/account-information',
  eDeliveryPreferences = '/account-management/delivery-preferences',
  proxyPreferences = '/account-management/proxy-preferences',
  accountInformationRedirect = 'account-information',
  buy = 'buy',
}

export const registerPaths = [
  RoutingPaths.register,
  RoutingPaths.onlineRegistration,
  RoutingPaths.externalRegister,
  RoutingPaths.registerDspp,
  RoutingPaths.registerDsppContinue,
];

export default RoutingPaths;
